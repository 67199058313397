import { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormHelperText,
  Divider,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  OutlinedInput,
  Checkbox,
  Button,
} from "@mui/material";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ManualIcon from "src/assets/svg-icons/manual.svg";
import FoodicsIcon from "src/assets/svg-icons/foodics.svg";
import ExcelIcon from "src/assets/svg-icons/excel.svg";
import FileUploader from "src/shared/components/file-uploader";
import { useParams, useSearchParams } from "react-router-dom";
import {
  useDeleteJourneyFile,
  useGetJourneyFiles,
  useUploadJourneyFileMutation,
} from "src/api/add-sales";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { convertObjectToFormData, formatBytes, formatNumber } from "src/utils";
import UploadedFile from "./uploaded-file";
import { FormData } from "../../constants";
import { JourneyTypes } from "src/types/generic";
import DateRangeInput from "src/shared/components/date-range";
import moment from "moment";
import CustomRadioGroup from "src/shared/components/custom-radio-group";
import YoutubeViewerBox from "../../youtube-viewer-box";
import InfoBox from "src/shared/components/info-box";
import { FormModeTypes } from "src/types/generic";
import { useTranslation } from "react-i18next";

type filesTypes = "orders" | "order_items" | "order_payments";

interface IDateRange {
  start_date: string;
  end_date: string;
}

const CalculateSales = ({
  control,
  errors,
  watch,
  setValue,
  journeyData,
  newPaymentsAggFields,
  appendNewPaymentsAgg,
  removeNewPaymentsAgg,
  newPayments,
  mode,
  journeyTaxPercentage,
  journeyTaxDivider,
  journeysDateRange,
  setFilesChanged,
  filesChanged,
}: {
  control: Control<FormData, any>;
  errors: FieldErrors<FormData>;
  watch: UseFormWatch<FormData>;
  setValue: UseFormSetValue<FormData>;
  journeyData: any;
  newPaymentsAggFields: any;
  appendNewPaymentsAgg: any;
  removeNewPaymentsAgg: any;
  newPayments: any[];
  mode: FormModeTypes;
  journeyTaxPercentage: number;
  journeyTaxDivider: number;
  journeysDateRange: IDateRange | undefined;
  setFilesChanged: Function;
  filesChanged: boolean;
}) => {
  let [searchParams] = useSearchParams();
  const { id } = useParams();
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [orderItemsFile, setOrderItemsFile] = useState<any>();
  const [orderPaymentsFile, setOrderPaymentsFile] = useState<any>();
  const [ordersFile, setOrdersFile] = useState<any>();
  const [fileType, setFileType] = useState<filesTypes>();

  // APIS
  const { mutate, isPending, error, status } = useUploadJourneyFileMutation();
  const { t, i18n } = useTranslation("addSales");
  const { t: generalT } = useTranslation("general");
  const {
    data: filesData,
    status: getFilesStatus,
    refetch,
    isLoading: isLoadingGetFiles,
    isFetching: isFetchingGetFiles,
  } = useGetJourneyFiles(
    (mode === "add" ? searchParams.get("journeyId") : id) || "",
    !!searchParams.get("journeyId") || !!id
  );

  const {
    mutate: mutateDeleteFile,
    status: deleteStatus,
    isPending: isPendingDelete,
  } = useDeleteJourneyFile();

  const handleFileUpload = (data: Blob, type: filesTypes) => {
    // Handle the file upload data for each XLSFileUploader instance
    const formDataToSend = convertObjectToFormData({
      journey_id: searchParams.get("journeyId") || "",
      type: type,
    });
    formDataToSend.append("file", data);
    setFileType(type);
    mutate(formDataToSend);
  };

  const handleDeleteFile = (id: string, type: filesTypes) => {
    setFileType(type);
    mutateDeleteFile(id);
  };

  // Uploading file error handling
  useEffect(() => {
    if (status === "success") {
      setFilesChanged(true);
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
      refetch();
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  // Deleting file error handling
  useEffect(() => {
    if (deleteStatus === "success") {
      // refetch();
      setFilesChanged(true);
      if (fileType === "order_items") {
        setOrderItemsFile(undefined);
      } else if (fileType === "order_payments") {
        setOrderPaymentsFile(undefined);
      } else {
        setOrdersFile(undefined);
      }
    } else if (deleteStatus === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: generalT("failedDeleteFile"),
        },
      ]);
    }
  }, [deleteStatus]);

  // Get files handling
  useEffect(() => {
    console.log("refetch", { getFilesStatus });
    if (getFilesStatus === "success") {
      console.log("success", { getFilesStatus });
      if (filesData?.data.journey_files.length > 0) {
        let order_items_file = filesData.data.journey_files.find(
          (file: any) => file.type === "order_items"
        );
        let order_payments_file = filesData.data.journey_files.find(
          (file: any) => file.type === "order_payments"
        );
        let orders_file = filesData.data.journey_files.find(
          (file: any) => file.type === "orders"
        );
        setOrderItemsFile(order_items_file);
        setOrderPaymentsFile(order_payments_file);
        setOrdersFile(orders_file);
        // set form values
        setValue("orderByItems", order_items_file ? "exist" : "");
        setValue("orderByPayment", order_payments_file ? "exist" : "");
      } else {
        setOrderItemsFile(undefined);
        setOrderPaymentsFile(undefined);
        setOrdersFile(undefined);
      }
    }
  }, [isFetchingGetFiles]);

  const handleAppendNewAgg = () => {
    appendNewPaymentsAgg({
      aggregator_name: "",
      aggregator_fee: "",
      payments: [],
    });
  };

  const handleRemoveAgg = (index: number) => {
    console.log({ index });
    removeNewPaymentsAgg(index);
  };

  const isOutsideRange = (day: moment.Moment) => {
    if (!!journeysDateRange) {
      const startDate = moment(journeysDateRange?.start_date);
      const endDate = moment(journeysDateRange?.end_date).add(1, "day");
      return day.isBetween(startDate, endDate, null, "[]");
    }
  };

  return (
    <div>
      <Box>
        <Typography fontSize={"26px"} fontWeight={600}>
          {t("calculateYourSales")}
        </Typography>
        <Typography color={"textSecondary"}>{t("letKnowYou")}</Typography>
        <YoutubeViewerBox videoId="ZgAMNB7x-lM" />
        <Stack gap={2} alignItems={"flex-start"} width={"100%"}>
          <Controller
            name="journey_type"
            control={control}
            render={({ field }) => (
              <>
                <CustomRadioGroup
                  options={[
                    {
                      label: t("manually"),
                      value: "manual",
                      icon: <img src={ManualIcon} alt="" />,
                      disabled: mode === "edit",
                    },
                    {
                      label: t("foodics"),
                      value: "foodics",
                      icon: <img src={FoodicsIcon} alt="" />,
                      disabled: mode === "edit",
                    },
                    {
                      label: t("excelSoon"),
                      value: "excel",
                      icon: <img src={ExcelIcon} alt="" />,
                      disabled: true,
                    },
                  ]}
                  {...field}
                  onChange={(value) =>
                    setValue("journey_type", value as JourneyTypes)
                  }
                />
              </>
            )}
          />
          {/* Will be displayed if only we have journeys (added already) (not onboarding) */}
          {journeysDateRange?.start_date && journeysDateRange.end_date ? (
            <InfoBox
              text={`${t("yourLastTimeRange")} ${
                journeysDateRange?.start_date
              } ${t("to")} ${journeysDateRange?.end_date}. ${t(
                "pleaseEnsure"
              )} ${moment(journeysDateRange?.end_date)
                .add(1, "day")
                .format("YYYY-MM-DD")}.`}
            />
          ) : null}

          {watch("journey_type") === "foodics" ? (
            <>
              {/* order Items File */}
              {orderItemsFile ? (
                <UploadedFile
                  label={t("goToFoodicsConsoleOrderItem")}
                  name={orderItemsFile?.file?.name}
                  size={formatBytes(orderItemsFile?.file?.size)}
                  handleDelete={() =>
                    handleDeleteFile(orderItemsFile.id, "order_items")
                  }
                  disabledDelete={
                    (fileType === "order_items" && isPendingDelete) ||
                    mode === "edit"
                  }
                  isLoading={fileType === "order_items" && isPendingDelete}
                />
              ) : (
                <FileUploader
                  label={t("goToFoodicsConsoleOrderItem")}
                  onFileUpload={(data) => handleFileUpload(data, "order_items")}
                  id="orderByItems"
                  name="orderByItems"
                  control={control}
                  error={!!errors.orderByItems}
                  helperText={errors.orderByItems?.message}
                  isLoading={
                    (isPending && fileType === "order_items") ||
                    isFetchingGetFiles
                  }
                />
              )}
              {/* order Payments File */}
              {orderPaymentsFile ? (
                <UploadedFile
                  label={t("goToFoodicsConsoleOrderPayments")}
                  name={orderPaymentsFile?.file?.name}
                  size={formatBytes(orderPaymentsFile?.file?.size)}
                  handleDelete={() =>
                    handleDeleteFile(orderPaymentsFile.id, "order_payments")
                  }
                  disabledDelete={
                    (fileType === "order_payments" && isPendingDelete) ||
                    mode === "edit"
                  }
                  isLoading={fileType === "order_payments" && isPendingDelete}
                />
              ) : (
                <FileUploader
                  label={t("goToFoodicsConsoleOrderPayments")}
                  onFileUpload={(data) =>
                    handleFileUpload(data, "order_payments")
                  }
                  id="orderByPayment"
                  name="orderByPayment"
                  control={control}
                  error={!!errors.orderByPayment}
                  helperText={errors.orderByPayment?.message}
                  isLoading={isPending && fileType === "order_payments"}
                />
              )}

              <Controller
                name="discount"
                control={control}
                render={({ field }) => (
                  <TextField
                    type="number"
                    label={t("discountOptional")}
                    {...field}
                    error={!!errors.discount}
                    helperText={errors.discount?.message}
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      shrink: field.value !== undefined ? true : false,
                    }}
                  />
                )}
              />
              <Controller
                name="charge_with_tax"
                control={control}
                render={({ field }) => (
                  <TextField
                    type="number"
                    label={t("chargeWithTax")}
                    {...field}
                    error={!!errors.charge_with_tax}
                    helperText={errors.charge_with_tax?.message}
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      shrink: field.value !== undefined ? true : false,
                    }}
                  />
                )}
              />
              <Controller
                name="tips"
                control={control}
                render={({ field }) => (
                  <TextField
                    type="number"
                    label={t("tipsOptional")}
                    {...field}
                    error={!!errors.tips}
                    helperText={errors.tips?.message}
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      shrink: field.value !== undefined ? true : false,
                    }}
                  />
                )}
              />
            </>
          ) : (
            <>
              <Divider sx={{ width: "100%" }} />
              <Stack width={"100%"} gap={2}>
                <Controller
                  name="dateRange"
                  control={control}
                  defaultValue={{
                    start_date: "",
                    end_date: "",
                  }}
                  render={({ field }) => (
                    <DateRangeInput
                      disabled={mode === "edit"}
                      startDate={
                        field.value.start_date
                          ? moment(field.value.start_date)
                          : null
                      } // moment.Moment | null;
                      startDateId={`your_unique_start_date_id`} // moment.Moment | null;
                      endDate={
                        field.value.end_date
                          ? moment(field.value.end_date)
                          : null
                      } // moment.Moment | null;
                      endDateId={`your_unique_end_date_id`} // string;
                      onDatesChange={(arg: {
                        startDate: moment.Moment | null;
                        endDate: moment.Moment | null;
                      }) => {
                        setValue("dateRange", {
                          start_date: arg.startDate?.format(
                            "yyyy-MM-DD"
                          ) as string,
                          end_date: arg.endDate?.format("yyyy-MM-DD") as string,
                        });
                      }}
                      isOutsideRange={isOutsideRange}
                    />
                  )}
                />
                <FormHelperText
                  error
                  id="my-helper-text"
                  sx={{ mt: -1, px: 2 }}
                >
                  {errors.dateRange?.start_date?.message ||
                    errors.dateRange?.end_date?.message}
                </FormHelperText>

                <Controller
                  name="net_sales_with_tax"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      required
                      label={t("netSalesWithTaxShould")}
                      {...field}
                      error={!!errors.net_sales_with_tax}
                      helperText={errors.net_sales_with_tax?.message}
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: field.value !== undefined ? true : false,
                      }}
                    />
                  )}
                />
                <Controller
                  name="total_order_quantity"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      required
                      label={t("totalOrderQuantity")}
                      {...field}
                      error={!!errors.total_order_quantity}
                      helperText={errors.total_order_quantity?.message}
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: field.value !== undefined ? true : false,
                      }}
                    />
                  )}
                />
                <Controller
                  name="discount"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      label={t("discountOptional")}
                      {...field}
                      error={!!errors.discount}
                      helperText={errors.discount?.message}
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: field.value !== undefined ? true : false,
                      }}
                    />
                  )}
                />
                <Controller
                  name="charge_with_tax"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      label={t("chargeWithTax")}
                      {...field}
                      error={!!errors.charge_with_tax}
                      helperText={errors.charge_with_tax?.message}
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: field.value !== undefined ? true : false,
                      }}
                    />
                  )}
                />
                <Controller
                  name="tips"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      label={t("tipsOptional")}
                      {...field}
                      error={!!errors.tips}
                      helperText={errors.tips?.message}
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: field.value !== undefined ? true : false,
                      }}
                    />
                  )}
                />
              </Stack>

              <Box width={"100%"}>
                <Box height={"7px"} bgcolor={"#D0D5DD"} />
                <Stack p={2} gap={1.5} bgcolor={"#F9FAFB"}>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography fontSize={"14px"} color={"#344054"}>
                      {generalT("sales")}:
                    </Typography>
                    <Typography fontSize={"14px"} color={"#344054"}>
                      {watch("net_sales_with_tax")
                        ? formatNumber(+watch("net_sales_with_tax"))
                        : "--"}{" "}
                      {generalT("sar")}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography fontSize={"14px"} color={"#344054"}>
                      {t("salesTax")}:
                    </Typography>
                    <Typography fontSize={"14px"} color={"#344054"}>
                      {watch("net_sales_with_tax")
                        ? formatNumber(
                            +(
                              (+watch("net_sales_with_tax") /
                                journeyTaxDivider) *
                              journeyTaxPercentage
                            ).toFixed(2)
                          )
                        : "--"}{" "}
                      {generalT("sar")}
                    </Typography>
                  </Stack>
                  <Divider sx={{ width: "100%" }} />
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography fontSize={"14px"} color={"#344054"}>
                      {t("netSalesTotal")}
                    </Typography>
                    <Typography fontSize={"14px"} color={"#344054"}>
                      {watch("net_sales_with_tax")
                        ? formatNumber(
                            +(
                              +watch("net_sales_with_tax") / journeyTaxDivider
                            ).toFixed(2)
                          )
                        : "--"}{" "}
                      {generalT("sar")}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>

              <Box width={"100%"}>
                <Box height={"7px"} bgcolor={"#D0D5DD"} />
                <Stack p={2} gap={1.5} bgcolor={"#F9FAFB"}>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography fontSize={"14px"} color={"#344054"}>
                      {generalT("discount")}:
                    </Typography>
                    <Typography fontSize={"14px"} color={"#344054"}>
                      {watch("discount")
                        ? formatNumber(Number(watch("discount")))
                        : "--"}{" "}
                      {generalT("sar")}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography fontSize={"14px"} color={"#344054"}>
                      {generalT("charge")}:
                    </Typography>
                    <Typography fontSize={"14px"} color={"#344054"}>
                      {watch("charge_with_tax")
                        ? formatNumber(Number(watch("charge_with_tax")))
                        : "--"}{" "}
                      {generalT("sar")}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography fontSize={"14px"} color={"#344054"}>
                      {generalT("tips")}:
                    </Typography>
                    <Typography fontSize={"14px"} color={"#344054"}>
                      {watch("tips")
                        ? formatNumber(Number(watch("tips")))
                        : "--"}{" "}
                      {generalT("sar")}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </>
          )}

          {/* For new payments found in foodics files */}
          {newPaymentsAggFields.length > 0 &&
            watch("journey_type") === "foodics" &&
            !filesChanged && (
              <Stack gap={2}>
                <InfoBox text={t("foundNewPayments")} />
                <Box>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    gap={{ xs: 1, sm: 1, md: 3 }}
                    flexWrap={"wrap"}
                    width={"100%"}
                  >
                    <Typography color={"textSecondary"}>
                      {t("isPaymentMethod")}
                    </Typography>
                    <FormControl
                      fullWidth
                      size="small"
                      error={!!errors.are_payments_new_agg}
                      sx={{
                        width: "fit-content",
                        ml: { xs: "auto", sm: "unset" },
                      }}
                    >
                      <Controller
                        name="are_payments_new_agg"
                        control={control}
                        render={({ field }) => (
                          <RadioGroup
                            {...field}
                            key={field.value}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value={"yes"}
                              control={
                                <Radio
                                  icon={<CircleOutlinedIcon />}
                                  checkedIcon={<CheckCircleIcon />}
                                />
                              }
                              label={generalT("yes")}
                              sx={{ color: "#98A2B3" }}
                            />
                            <FormControlLabel
                              value="no"
                              control={
                                <Radio
                                  icon={<CircleOutlinedIcon />}
                                  checkedIcon={<CheckCircleIcon />}
                                />
                              }
                              label={generalT("no")}
                              sx={{ color: "#98A2B3" }}
                            />
                          </RadioGroup>
                        )}
                      />
                    </FormControl>
                  </Stack>
                  <FormHelperText error id="my-helper-text" sx={{ px: 2 }}>
                    {errors.are_payments_new_agg?.message}
                  </FormHelperText>
                </Box>
                {watch("are_payments_new_agg") === "yes" &&
                  newPaymentsAggFields.map((fieldItem: any, index: number) => {
                    return (
                      <Stack key={fieldItem.key} gap={2}>
                        {/* name */}
                        <Controller
                          name={`new_payments_aggregators.${index}.aggregator_name`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              label={generalT("aggName")}
                              {...field}
                              error={
                                errors.new_payments_aggregators &&
                                !!errors.new_payments_aggregators[index]
                                  ?.aggregator_name
                              }
                              helperText={
                                errors.new_payments_aggregators &&
                                errors.new_payments_aggregators[index]
                                  ?.aggregator_name?.message
                              }
                              fullWidth
                              size="small"
                            />
                          )}
                        />
                        {/* fee */}
                        <Controller
                          name={`new_payments_aggregators.${index}.aggregator_fee`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              label={`${generalT("aggFee")} %`}
                              type="number"
                              {...field}
                              error={
                                errors.new_payments_aggregators &&
                                !!errors.new_payments_aggregators[index]
                                  ?.aggregator_fee
                              }
                              helperText={
                                errors.new_payments_aggregators &&
                                errors.new_payments_aggregators[index]
                                  ?.aggregator_fee?.message
                              }
                              fullWidth
                              size="small"
                            />
                          )}
                        />
                        {/* Related payments */}
                        <FormControl
                          fullWidth
                          size="small"
                          error={
                            errors.new_payments_aggregators &&
                            !!errors.new_payments_aggregators[index]?.payments
                          }
                        >
                          <InputLabel id="demo-multiple-checkbox-label">
                            {generalT("selectPayments")}
                          </InputLabel>
                          <Controller
                            name={`new_payments_aggregators.${index}.payments`}
                            control={control}
                            defaultValue={[]}
                            render={({ field }) => (
                              <Select
                                {...field}
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                input={
                                  <OutlinedInput
                                    label={generalT("selectPayments")}
                                  />
                                }
                                renderValue={(selected) =>
                                  selected
                                    .map(
                                      (selectedId: string) =>
                                        newPayments?.find(
                                          (payment: any) =>
                                            payment.id === +selectedId
                                        ).name
                                    )
                                    .join(", ")
                                }
                              >
                                {newPayments
                                  .filter((payment) => {
                                    return !watch(
                                      "new_payments_aggregators"
                                    ).some(
                                      (item, i) =>
                                        item?.payments?.includes(
                                          payment.id.toString()
                                        ) && i !== index
                                    );
                                  })
                                  ?.map((payment: any) => (
                                    <MenuItem
                                      key={payment.id}
                                      value={`${payment.id}`}
                                    >
                                      <Checkbox
                                        checked={
                                          field.value &&
                                          field.value.indexOf(`${payment.id}`) >
                                            -1
                                        }
                                      />
                                      {payment.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            )}
                          />
                          <FormHelperText id="my-helper-text">
                            {errors.new_payments_aggregators &&
                              errors.new_payments_aggregators[index]?.payments
                                ?.message}
                          </FormHelperText>
                        </FormControl>
                        {newPaymentsAggFields.length > 1 && (
                          <Button
                            variant="outlined"
                            color="warning"
                            onClick={() => handleRemoveAgg(index)}
                            size="small"
                            sx={{ width: "fit-content", mx: "auto" }}
                          >
                            {generalT("delete")}
                          </Button>
                        )}
                        <Divider sx={{ width: "100%" }} />
                      </Stack>
                    );
                  })}
                <Button
                  sx={{ width: "180px", mx: "auto" }}
                  variant="contained"
                  onClick={handleAppendNewAgg}
                >
                  {generalT("addNewAgg")}
                </Button>
              </Stack>
            )}
        </Stack>
      </Box>
    </div>
  );
};

export default CalculateSales;
