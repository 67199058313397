import { Stack, Typography, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import dashboard from "src/assets/img/dashboard.png";
import logo from "src/assets/svg-icons/logo.svg";
import Form from "./components/form";
import Footer from "src/shared/components/auth-pages-footer/footer";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SingUpPage = () => {
  const { t } = useTranslation("auth");

  return (
    <Grid container p={{ xs: 1, md: 3 }}>
      <Grid
        xs={12}
        md={5}
        px={{ xs: "16px", lg: "80px" }}
        py={{ xs: "16px", md: "46px" }}
      >
        <Stack mb={"32px"}>
          <img src={logo} alt="jazar logo" width={"90px"} height={"42px"} />
          <Typography
            color={"#1D2939"}
            fontSize={"32px"}
            fontWeight={600}
            lineHeight={"38.4px"}
            mb={2}
            mt={"42px"}
          >
            {t("carveYourPath")}.
          </Typography>
          <Typography
            color={"#1D2939"}
            fontSize={"16px"}
            lineHeight={"19.2px"}
            mb={"42px"}
          >
            {t("sayGoodbyeGuesswork")}.
          </Typography>
          <Form />
        </Stack>
        <Stack
          direction={"row"}
          spacing={"4px"}
          mb={{ xs: "50px", md: "105px" }}
          alignItems={"baseline"}
        >
          <Typography fontSize={"16px"} color={"#1D2939"} lineHeight={"20px"}>
            {t("alreadyHaveAccount")}
          </Typography>
          <Link
            color={"#1D2939"}
            component={RouterLink}
            underline="hover"
            to="/login"
            fontWeight={600}
          >
            {t("login")}.
          </Link>
        </Stack>
        <Footer />
      </Grid>
      <Grid
        md={7}
        display={{ xs: "none", md: "flex" }}
        flexDirection={"column"}
        alignItems={"center"}
        bgcolor={"#F9FAFB"}
        borderRadius={2}
      >
        <Typography
          color={"#0C111D"}
          fontSize="36px"
          fontWeight={600}
          lineHeight="43.2px"
          pt="59px"
          mb="22px"
          sx={{ width: { lg: "649px", md: "295px" } }}
          textAlign={"center"}
        >
          {t("nourishingYourProfits")}{" "}
          <Typography
            component={"span"}
            color={"#FF8D3F"}
            fontSize="36px"
            fontWeight={600}
            lineHeight="43.2px"
          >
            {" "}
            {t("oneCalculation")}
          </Typography>{" "}
          {t("atATime")}!
        </Typography>
        <Typography
          color={"#475467"}
          textAlign={"center"}
          fontSize="16px"
          lineHeight="19.2px"
          width="453px"
          mb={2}
        >
          {t("YouWillHaveTheTools")}{" "}
          <Typography
            component={"span"}
            color={"#FF8D3F"}
            fontWeight={600}
            fontSize="16px"
            lineHeight="19.2px"
          >
            {t("jazr")}
          </Typography>{" "}
          {t("today")}
        </Typography>
        <img src={dashboard} alt="sing up" width={"100%"} />
      </Grid>
    </Grid>
  );
};

export default SingUpPage;
